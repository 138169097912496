import './App.css';
import Home from './comps/Home/Home.js';

function App() {
  return (
    <Home/>
  );
}

export default App;
